import React, { useEffect } from "react"

import { Widget } from "@typeform/embed-react"
import useFeatureFlagContext from "decentraland-gatsby/dist/context/FeatureFlag/useFeatureFlagContext"
import { Loader } from "decentraland-ui/dist/components/Loader/Loader"

import EventNavbar from "../../../components/EventNavbar/EventNavbar"
import Layout from "../../../components/Layout/Layout"
import NotFound from "../../../components/NotFound/NotFound"
import useEventQuery from "../../../hooks/useEventQuery"
import { FeatureFlag } from "../../../modules/ff"

import "./quiz.css"

export default function EventQuiz(props: { event: string }) {
  const [ff, { loaded: featureFlagsLoaded, loading: featureFlagsLoading }] =
    useFeatureFlagContext()
  const event = useEventQuery(props.event)

  useEffect(() => {
    if (event) {
      document.documentElement.style.setProperty(
        "--event-background-color",
        event.background_color || ""
      )
    }
  }, [event])

  if (!featureFlagsLoaded || featureFlagsLoading) {
    return <Loader active size="large" />
  }

  if (!ff.flags[FeatureFlag.ConferencePage] || !event) {
    return <NotFound />
  }

  return (
    <Layout className="event-quiz" hideFooter hideNavbar>
      <EventNavbar
        logo={event.logo.url}
        title={event.title}
        id={event.event_id}
      />
      <Widget id={event.typeform_quiz_id} className="event-quiz__typeform" />
    </Layout>
  )
}
